import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import Login from "./Login";
import Registration from "./Registration";

const StyledAuthentication= styled.div`
  background-color: hsl(240deg 68% 16% / 5%);
  max-width: 400px;
  padding: 0 20px 20px;
  h1{
    font-size: 24px;
  }
  .navigation_bar {
    display: flex;
    .login_nav,
    .registration_nav {
      width: 50%;
      border-bottom: 2px solid #5249651a;
      padding: 15px;
      text-align: center;
      cursor: pointer;
      color: #524965;
      opacity: 0.6;
      font-size: 14px;
      &.active {
        border-bottom: 2px solid ${({theme}) => theme.colors.brand};
        opacity: 1;
      }
    }
    .login_nav {
    
    }
    .registration_nav {
   
    }
  }
`;

const Authentication = (props) => {
  const { type, dispatch } = props;
  const [activeTab, setActiveTab] = useState(type);
  return (
    <StyledAuthentication>
      <div className="navigation_bar">
        <div onClick={()=>setActiveTab('login')} className={`login_nav ${activeTab === 'login'? 'active':''}`}>
          Login
        </div>
        <div onClick={()=>setActiveTab('registration')} className={`registration_nav ${activeTab === 'registration'? 'active':''}`}>
          Registration
        </div>
      </div>
      {activeTab === 'login' && <Login dispatch={dispatch}/>}
      {activeTab === 'registration' && <Registration dispatch={dispatch}/>}
    </StyledAuthentication>
  )
};
Authentication.propTypes = {
  type: PropTypes.string
};

export default React.memo(props => <Authentication {...props} />);
