import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, {useState} from "react";
import StyledMenuCollapse from "./MenuCollapse.styled";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io'
import {ReactComponent as IconMyGames} from "../../images/icons/ic_my_games.svg"
import {useTranslation} from "react-i18next";
import UserAvatar from "../UserAvatar/userAvatar";

const MenuItem = ({name, children, path, first, level, IconPath, img}) => {
    const [open, setOpen] = useState(false);
    const onClick = (children) => {
        if(children && children.length > 0) {
            setOpen(!open)
        }
    }
    const { t } = useTranslation();
    return (
      <div>
          {path ? <Link className={`main_menu_element level_${level} ${first?"first":''}`} to={path}>
                {IconPath && !img  && <IconPath/>}
                {img && <img src={img} alt="" width="12"/>}
              <span>{t(name)}</span>
          </Link>
            :
            <div className={`main_menu_element level_${level} ${first?"first":''}`} onClick={()=>onClick(children)}>
              <span>{t(name)}</span>
              {open
                ?
                    <IoIosArrowUp/>
                :
                    <IoIosArrowDown/>
              }
          </div>}
        
          {open && <div className={`sub_menu_element_box ${open ? "open" : "close"}`}>
              {children && children.map((child)=>{
                  return <MenuItem {...child} />
              })}
          </div>}
      </div>
    );
};
const MenuCollapse = React.memo(props => {
    const {className, menuItems, user, dispatch} = props;
    const loginStatus = localStorage.getItem('LoginStatus');
    const { t } = useTranslation();

    return (

        <StyledMenuCollapse className={className}>
            {loginStatus && <UserAvatar photo={user?.photo} firstName={user?.first_name} lastName={user?.last_name} dispatch={dispatch} />}
            {menuItems.map((item,i)=>{
                return <MenuItem {...item}/>
            })}
            {loginStatus &&
                <Link to="/home?myGames=true" className="my_games">
                    <IconMyGames />
                    <span>
                        {t("My games")}
                    </span>
                </Link>
            }
            <Link to="/faq" className=" main_menu_element mobile_menu-item">
                <span>
                    {t("FAQ")}
                </span>
            </Link>
            <Link to="/about" className="main_menu_element mobile_menu-item">
                <span>
                    {t("About")}
                </span>
            </Link>
            <Link to="/support" className="main_menu_element mobile_menu-item">
                <span>
                    {t("Support")}
                </span>
            </Link>
        </StyledMenuCollapse>
    );
});

const mapStateToProps = ({ userInfo }) => ({
    userInfo,
    user: userInfo.user,
});

MenuCollapse.propTypes = {
    className:PropTypes.string
};

export default connect(mapStateToProps)(MenuCollapse);
