
/* Base Colors */
const brand = "#FD3C60"


/* Misc Colors */
const success = "#3abf7c";


export const colors = {
    success,
    brand,
};
export const sizes = {
    mainContainer: "1364px",
    borders: {
        radius: 3,
        width: 1.5
    },
    padding: {
        table: {
            normal: "7px 5px",
            compact: "20px 5px"
        },
        input: "1rem 0.8rem",
        select: ".6em 2.2em .5em .8em"
    },

    animations: {
        duration: 250,
        slider: {
            speed: 1000,
            autoplaySpeed: 5000
        }
    },

    icons: {
        size: 12
    },

    fonts: {
        text: "1rem"
    },

    skeleton: {
        height: "1em",
        width: "6em"
    }
};
