import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import React from "react";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "online": "online",
      "status": "Status",
      "Update": "Update",
      "startDate": "Start Date",
      "endDate": "End Date",
      "players": "Players",
      "subscribe": "SUBSCRIBE",
      "prizes": "prizes",
      "quizRules": "Quiz Rules",
      "leaderboard": "leaderboard",
      "see_my_results": "see my results",
      "follow": "Follow",
      "About US": "About US",
      "FAQ": "FAQ",
      "About": "About",
      "Support": "Support",
      "Search": "Search",
      "My profile": "My profile",
      "Name": "Name",
      "Surname": "Surname",
      "Email": "Email",
      "Phone Number": "Phone Number",
      "Birth Date": "Birth Date",
      "Country": "Country",
      "ID card": "ID card",
      "Change Password": "Change Password",
      "Edit Profile": "Edit Profile",
      "Date Of Birth": "Date Of Birth",
      "Choose your country": "Choose your country",
      "Save": "Save",
      "Old Password": "Old Password",
      "New Password": "New Password",
      "Withdraw amount": "Withdraw amount",
      "withdraw": "withdraw",
      "Withdraw": "Withdraw",
      "Deposit": "Deposit",
      "TransactionHistory amount": "Transaction History amount",
      "purchase": "purchase",
      "My transaction history": "My transaction history",
      "Add deposit": "Add deposit",
      "Games": "Games",
      "All Games": "All Games",
      "Categories": "Categories",
      "Recommended": "Recommended",
      "New!": "New!",
      "My games": "My games",
      "don't miss them": "don't miss them",
      "to the top": "to the top",
      "Popular Games": "Popular Games",
      "New Games": "New Games",
      "Active Game": "Active Game",
      "Finished games": "Finished games",
      "YOU DID IT!!!": "YOU DID IT!!!",
      "Time Left": "Time Left",
      "Current Task Number": "Current Task Number",
      "Guarantee": "Guarantee",
      "Log out": "Log out",
      "Details": "Details",
      "Results": "Results",
      "Get Started": "Get Started",
      "Sign up Now it’s Easy!": "Sign up Now it’s Easy!",
      "Password": "Password",
      "Email Address": "Email Address",
      "go to play": "Go to play",
      "subscribed": "Subscribed",
      "Waiting for the end of time": "Waiting for the end of time",
      "GO TO THE NEXT ROUND": "GO TO THE NEXT ROUND",

    }
  },
  ru: {
    translation: {
      "online": "в сети",
      "status": "Статус",
      "Update": "Обновить",
      "startDate": "Дата начала",
      "endDate": "Дата оканчания",
      "players": "Игроки",
      "subscribe": "Подписаться",
      "prizes": "Призы",
      "quizRules": "Правила игры",
      "leaderboard": "Список лидеров",
      "see_my_results": "посмотреть мои результаты",
      "follow": "Подписаться",
      "About US": "О нас",
      "Search": "Поиск",
      "Name": "Имя",
      "Edit Profile": "Редактировать профиль",
      "Withdraw": "Снять со счета",
      "Deposit": "Депозит",
      "Add deposit": "Добавить депозит",
      "Games": "Игры",
      "All Games": "Все игры",
      "Categories": "Категории",
      "Recommended": "Рекомендации",
      "New!": "Новые!",
      "My games": "Мои игры",
      "don't miss them": "НЕ УПУСТИТЕ",
      "to the top": "Лучшее",
      "Popular Games": "Популярные Игры",
      "New Games": "Новые Игры",
      "Active Game": "Активные Игры",
      "Finished games": "Завершенные Игры",
      "YOU DID IT!!!": "ВЫ ВЫИГРАЛИ!!!",
      "Time Left": "Оставшееся время",
      "Current Task Number": "Текущий номер задачи",
      "Guarantee": "Гарантия",
      "Log out": "Выйти",
      "Phone Number": "Телефонный номер",
      "Surname": "Фамилия",
      "Email": "Эл.почта",
      "Date Of Birth": "Дата рождения",
      "Choose your country": "Выберите вашу страну",
      "Change Password": "Изменить пароль",
      "Old Password": "Старый пароль",
      "New Password": "Новый пароль",
      "Save": "Сохранить",
      "Transaction History amount": "Сумма истории транзакций",
      "Withdraw amount": "Снятия сумма",
      "Edit": "Ред.",
      "Birth Date": "Дата рождения",
      "Country": "Страна",
      "ID card": "Идентификационная карта",
      "My profile": "Мой профиль",
      "purchase": "покупка",
      "My transaction history": "Моя история транзакций",
      "Details": "Детали",
      "Results": "Результаты",
      "Support": "Помощь",
      "About": "О нас",
      "Get Started": "Начать",
      "Sign up Now it’s Easy!": "Зарегистрируйтесь сейчас это просто!",
      "Password": "Пароль",
      "Email Address": "Адрес электронной почты",
      "go to play": "Войти в игру",
      "subscribed": "Вы подписаны",
      "Waiting for the end of time": "Ждем окончания времени",
      "GO TO THE NEXT ROUND": "GO TO THE NEXT ROUND",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;