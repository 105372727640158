import styled from "styled-components";

const StyledHome = styled.div`
  display: flex;
  background-color: #dddbe0;
  .main_content {
    background-color: #dfdfe4;
    width: calc(100% - 300px);
    @media(max-width: 1150px){
      width: 100%;
  }
  }
`;

export default StyledHome;
