import React, { useState } from "react";
import styled from "styled-components";
import InputText from "../../ui/Inputs/InputText";
import Button from "../../ui/Button";
import {authActions} from "../../../actions";
import {connect} from "react-redux";
import {paymentActions} from "../../../actions/payment.actions";
import {useTranslation} from "react-i18next";

const StyledDeposit= styled.form`
  background-color: hsl(240deg 68% 16% / 5%);
  max-width: 400px;
  padding: 0 20px 20px;
  .forgot-password__header {
    border-bottom: 2px solid #5249651a;
    padding: 15px 0;
    color: #524965;
    opacity: 0.8;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .forgot-password__text {
    padding: 28px 0;
    color: #524965;
    opacity: 0.5;
    font-size: 14px;
  }
  .input_area {
    margin-bottom: 33px;
    input {
      font-size: 14px;
    }
  }
  .button_area {
    .resetSubmit {
      text-transform: uppercase;
      margin: 5px 0;
    }
  }
`;

const Deposit = (props) => {
  const { dispatch, errors } = props;
  const [amount, setAmount] = useState('');
    const handleSubmit = (e) =>{
        e.preventDefault();
        dispatch(paymentActions.addDeposit({
            amount,
            description: 'deposit'
        }));
    }
  const { t } = useTranslation();
  return (
    <StyledDeposit onSubmit={handleSubmit}>
      <div className="forgot-password__header">
          {t("Deposit")}
      </div>
      {/*<div className="forgot-password__text">*/}
      {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
      {/*</div>*/}
      <div className="input_area">
        <InputText type="number"
                   value={amount}
                   onChange={(e)=>setAmount(e.target.value)}
                   label={t("Transaction History amount")}
                   errorMessage={errors?.amount?.[0]}
        />
      </div>
      <div className="button_area">
        <Button type="submit" className="resetSubmit" width='100%'>{t("purchase")}</Button>
      </div>
    </StyledDeposit>
  )
};
function mapStateToProps({ alert }) {
    return {
        errors: alert.message
    };
}
export default connect(mapStateToProps)(Deposit);
