import { REQUEST_LOADER, STOP_LOADER } from '../config/actionTypes'

export function loader(state = {loading: false}, action) {
  switch (action.type) {
    case REQUEST_LOADER:
      return {
        loading: true
      };
    case  STOP_LOADER:
      return {
        loading: false
      };
    default:
      return state
  }
}