import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  padding: 0 18px;
  background-image: linear-gradient(to right, #FF8038, #FD3C60);
  color: #fff;
  font-size: ${props => props.fontSize ? `${props.fontSize}` : '14px'};
  letter-spacing: 1px;
  text-align: center;
  flex-shrink: 0;
  transition: var(--button-transition);
  width: ${props => props.width ? `${props.width}` : 'auto'};
  height: ${props => props.height ? `${props.height}` : "40px"};
  border-radius: 4px;
  font-family: var(--font-roboto);
  font-weight: 500;
  &:hover,
  &:focus {
    outline: none;
    background-image: linear-gradient(to right, #FC8744, #BE0023);
  }
  &:disabled {
    background-color: #717171;
    background-image: none;
    cursor: no-drop;
  }
`;

StyledButton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string
};

export default React.memo(props => <StyledButton {...props} />);
