import styled from "styled-components";

const StyledHeaderMain = styled.header`
    background-color: #dfdfe4;
    padding: 17px 15px 14px 55px;
    border-bottom: 1px solid rgba(82,73,101,0.1);
    .main_header_container{
      display: flex;
      justify-content: space-between;
      .search {
        position: relative;
        input {
          background-color: #d6d5dd;
          border: none;
          border-radius: 8px;
          padding: 10px 0 10px 40px;
          &:focus,
          &:hover {
            outline: none;
          }
          @media(max-width: 500px){
            width: 115px;
          }
        }
        button {
          position: absolute;
          left: 0;
          padding: 10px;
           &:focus,
           &:hover {
             outline: none;
           }
        }
      }
      .header_menu {
        display: flex;
        justify-content: space-between;
        width: 100%;
        nav ul{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          li{
            padding: 10px 12px;
            &.languages{
              position: relative;
              cursor: pointer;
               .dropdown-lang {
                 position: absolute;
                 background-color: #fff;
                 padding: 5px 10px;
                 border-radius: 6px;
                 top: 35px;
                 left: 12px;
                 .lan_row {
                  padding: 5px;
                 }
               }
            }
            a{
              color: #524965;
              opacity: 0.6;
              font-size: 14px;
              font-family: var(--font-roboto);
              font-weight: 500;
            }
          }
        }
        .user_info_header {
          display: flex;
          justify-content: space-between;
          .balance_box {
            display: flex;
            justify-content: center;
            flex-direction: column;
            .balance {
              padding: 3px;
              background-color: #FFFFFF;
              border-radius: 5px;
              display: flex;
              .coins {
                display: flex;
                .iconCoin {
                  width: 18px;
                  height: 18px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 4px;
                  background-color: ${({ theme }) => theme.colors.brand};
                  box-shadow: 0 0 12px 0 rgba(253,60,96,0.5);
                }
                .coinCount {
                  color: ${({ theme }) => theme.colors.brand};
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  padding: 0 4px;
                  font-size: 13px;
                  font-weight: 500;
                }
              }
              .coinsControl {
                display: flex;
                padding: 0 10px;
                .plus,
                .minus {
                  padding: 0 5px;
                  cursor: pointer;
                  svg {
                    width: 12px;
                  }
                }
                .minus {
                  svg {
                    stroke: ${({ theme }) => theme.colors.brand};
                  }
                }
                .plus {
                  svg {
                    stroke: ${({ theme }) => theme.colors.success};
                  }
                }
              }
            }
          }
         
        }
      }
    }
    @media(max-width: 991px){
     .desc_menu{
        display: none!important;
     }
    }
`;
export default StyledHeaderMain;
