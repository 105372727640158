import styled from "styled-components";

const StyledSideMenu = styled.div`
   width: 300px;
   height: 100vh;
   flex-shrink: 0;
   background-color: #cfccd4;
   .logo {
      img {
        margin: 21px 35px;
      }
   }
   .open_menu{
    display: none;
   }
  @media(max-width: 1150px){
      position: fixed;
      transform: translateX(-100%);
      height: 100vh;
      left: 0;
      top: 0;
      z-index: 15;
      transition: transform .6s;
      &.active{
        transform: translateX(0);
      }
      .open_menu{
        display: block;
        position: absolute;
        right: -45px;
        top: 16px;
      }
  }
  
`;
export default StyledSideMenu;
