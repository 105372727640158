import React from "react";
import { connect } from "react-redux";
import { modalActions } from "../../actions/index";
import { Modal, ModalBody } from "reactstrap";
import CloseSvg from '../../images/icons/ic_close.svg'
import styled from "styled-components";

const StyledPopup = styled(Modal)`
  max-width: 400px;
  .modal-content {
    border: none;
    .close_mod {
      display: flex;
      position: absolute;
      top: -17px;
      right: -17px;
    }
    .modal-body {
      padding: 0;
    }
  }
`;


const Popup = React.memo((props) => {

  return (
      <StyledPopup
        isOpen={props.modals.open_modal}
        toggle={props.close_modal}
        className="modal-dialog-centered"
      >
        <button
          type="button"
          className="close_mod"
          onClick={props.close_modal}
        ><img src={CloseSvg} alt=""/></button>
        <ModalBody>
          {props.modals.children}
        </ModalBody>
      </StyledPopup>
  );
});

function mapStateToProps(state) {
  return {
    modals: state.modals,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    close_modal: () => dispatch(modalActions.close_modal() ),
  };
}
const connectedPopup = connect(mapStateToProps, mapDispatchToProps)(Popup);
export { connectedPopup as Popup };
