import {OPEN_MODAL, CLOSE_MODAL, GET_USER_INFO} from '../config/actionTypes'
import {alertActions} from "./alert.actions";
import success from "../components/utility/toast";

export const modalActions = {
    open_modal,
    close_modal
};
 function open_modal(children) {
    return{
        type: OPEN_MODAL,
        children
    }
}
function close_modal() {
    return dispatch => {
        dispatch(alertActions.clear())
        dispatch(success());
    }
    function success() { return { type: CLOSE_MODAL } }
}