import { REQUEST_POPULAR_LOADER, STOP_POPULAR_LOADER } from '../config/actionTypes'

export function loaderPopular(state = {loading: false}, action) {
  switch (action.type) {
    case REQUEST_POPULAR_LOADER:
      return {
        loading: true
      };
    case  STOP_POPULAR_LOADER:
      return {
        loading: false
      };
    default:
      return state
  }
}