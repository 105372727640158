import {
    GET_LANGUAGES_INFO,
    GET_USER_INFO,
    GET_CATEGORIES_INFO
} from '../config/actionTypes';

export function userInfo(state = {}, action) {
    switch (action.type) {
        case GET_USER_INFO:
            return {
                user: action.user,
            };
        default:
            return state
    }
}

export function languagesInfo(state = {}, action) {
    switch (action.type) {
        case GET_LANGUAGES_INFO:
            return {
                languages: action.languages,
            };
        default:
            return state
    }
}

export function categoriesInfo(state = {}, action) {
    switch (action.type) {
        case GET_CATEGORIES_INFO:
            return {
                categories: action.categories,
            };
        default:
            return state
    }
}
