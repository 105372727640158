import { SUCCESS_ALERT, ERROR_ALERT, ERROR_ALERT_VERIFY, CLEAR_ALERT } from '../config/actionTypes'

export function alert(state = {}, action) {
  switch (action.type) {
    case SUCCESS_ALERT:
      return {
        type: 'success_alert',
        message: action.message
      };
    case  ERROR_ALERT:
      return {
        type: 'error_alert',
        message: action.message
      };
     case ERROR_ALERT_VERIFY:
      return {
        type: 'error_alert_verify',
        message: action.message
      };
    case CLEAR_ALERT:
      return {};
    default:
      return state
  }
}