import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import {MdKeyboardArrowDown} from 'react-icons/md'
import CheckedSvg from "../../../images/icons/ic_arrow.svg"

const StyledInputCheckbox = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray4};
  cursor: pointer;
  input{
    display: none;
    
    &:checked + .check_icon svg{
        display: flex
    }
  } 
  .check_icon{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    border: none;
    background-color: #ffffff;
   
     svg {
      color: ${({ theme }) => theme.colors.brand}; 
      display: none;
     }
  }
  .text_label {
    color: #524965;
    opacity: 0.5;
    font-size: 10px;
  }
`;

const InputCheckbox = (props) => {
  const { type, defaultValue, onChange, name, label } = props;
  return (
    <StyledInputCheckbox>
      <input className="t-form-input"
             onChange={onChange}
             name={name}
             type={type}
             defaultValue={defaultValue}
      />
      <span className="check_icon">
        <MdKeyboardArrowDown />
      </span>
      <span className="text_label">{label}</span>
    </StyledInputCheckbox>
  )
};
InputCheckbox.propTypes = {
  // type: PropTypes.string
};

export default React.memo(props => <InputCheckbox {...props} />);
