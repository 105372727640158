import styled from "styled-components";

const StyledSideMenu = styled.div`
  width: 100%;
  .main_menu_element {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #524965;
    padding: 9px 30px;
    cursor: pointer;
    text-decoration: none;
    &.first {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .sub_menu_element_box {
    .main_menu_element {
      &.level_1 {
        padding-left: 15px;
      }
      &.level_2 {
        padding-left: 30px;
      }
      &.level_3 {
        border-left: 3px solid transparent;
        padding: 11px 56px;
        justify-content: flex-start;
        span {
          padding: 1px 27px;
        }
        svg {
          path {
            fill: #6c647e;
          }
        }
        &:hover,
        &.active {
          background-color: rgba(253,60,96,0.05);
          border-left: 3px solid #FD3C60;
          img{
            opacity: 0.5;
          }
          svg {
            opacity: 0.5;
            //path {
            //  fill: #FD3C60;
            //}
          }
        }
      }
    }
  }
  .my_games {
    text-transform: uppercase;
    padding: 43px 30px;
    font-size: 14px;
    font-weight: 500;
    color: #524965;
    text-decoration: none;
    display: inline-block;
    width: 100%;
    svg {
      margin-right: 10px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }
 
  @media(min-width: 900px){
    .user_details, .mobile_menu-item{
      display: none!important;
    }
  }
  .user_details{
    margin-left: 30px!important;
    margin-bottom: 15px;
  }
`;
export default StyledSideMenu;
