import { userService } from '../services';
import { GET_USER_INFO, GET_LANGUAGES_INFO, GET_CATEGORIES_INFO } from '../config/actionTypes';
import {modalActions} from "./modal.actions";
import {alertActions} from "./alert.actions";
import * as toast from "../components/utility/toast";

export const globals = {
    userInfo,
    updateProfile,
    uploadIdCard,
    getLanguages,
    getCategories,
    changePassword
};

function userInfo() {
    return dispatch => {
        userService.GetInfo('profile', '')
            .then(
                user => {
                    dispatch(success(user?.client));
                }
            );
    };
    function success(user) { return { type: GET_USER_INFO, user } }
}
function updateProfile(data) {
    return dispatch => {
        userService.PostFile('profile/update', data)
            .then(
                () => {
                    dispatch(globals.userInfo())
                    dispatch(modalActions.close_modal() )
                },
                error => {
                    if(error.errorCode !== 403){
                        dispatch(alertActions.verify_error(error.errors));
                    }
                }
            )
        ;
    };
}
function changePassword(data) {
    return dispatch => {
        userService.PostInfo('profile/password-reset', data)
            .then(
                data => {
                    dispatch(modalActions.close_modal() )
                    dispatch(alertActions.clear())
                    toast.success({ text: data.message })
                },
                error => {
                    if(error.errorCode !== 403){
                        dispatch(alertActions.verify_error(error.errors));
                    }
                }
            )
        ;
    };
}
function uploadIdCard(file) {
    return dispatch => {
        return userService.PostFile('profile/id-document', file)
            .then(
                () => {
                    dispatch(globals.userInfo())
                    dispatch(alertActions.clear())
                },
                error => {
                    if(error.errorCode !== 403){
                        dispatch(alertActions.verify_error(error.errors));
                    }
                }
            );
    };
}
function getLanguages() {
    return dispatch => {
        userService.GetInfo('languages', '')
            .then(
                lang => {
                    dispatch(success(lang));
                }
            );
    };
    function success(languages) { return { type: GET_LANGUAGES_INFO, languages } }
}
function getCategories() {
    return dispatch => {
        userService.GetInfo('categories', '')
            .then(
                categories => {
                    dispatch(success(categories));
                }
            );
    };
    function success(categories) { return { type: GET_CATEGORIES_INFO, categories } }
}