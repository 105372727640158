import PropTypes from "prop-types";
import { connect } from "react-redux";
import React from "react";
import StyledHeaderSimple from "./HeaderSimple.styled";
import { Link } from "react-router-dom";
import Button from "../../components/ui/Button";
import ArrowRight from "../../images/icons/arrow_right_alt.svg"
import Logo from "../../images/logo.svg"
import Authentication from "../popups/Authentication/Authentication";
import {modalActions} from "../../actions";
import {history} from "../../helpers";
import {useMediaQuery} from "react-responsive";

const HeaderSimple = React.memo(props => {
    const { logo, className, dispatch } = props;
    const openModal = (data) =>{
        dispatch(modalActions.open_modal(data))
    }
    const mediaMenu = useMediaQuery({
        query: '(max-width: 991px)'
    })
    const noLogin =   history.location.pathname === "/" || history.location.pathname === "/about" ||  history.location.pathname === "/privacy-policy";
    return (
        <StyledHeaderSimple className={className}>
            <div className="main_container">
                <Link to='/' className="logo">
                    {noLogin && !mediaMenu && <img src={Logo} alt="logo"/>}
                </Link>
                <nav>
                    <ul>
                        <li className="mobile_menu-item">
                            <Link to="/about">About</Link>
                        </li>
                        <li className="mobile_menu-item">
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                        <li className="modal_list">
                            <button
                                 onClick={()=>openModal(
                                    <Authentication dispatch={dispatch} type="login" />
                                 )}
                            >Sign in</button>
                        </li>
                        <li>
                            <Button
                              className="registration_button"
                              onClick={()=>openModal(
                                <Authentication dispatch={dispatch} type="registration" />
                              )}
                            >Register <img src={ArrowRight} alt="arrow right"/></Button>
                        </li>
                    </ul>
                </nav>
            </div>
        </StyledHeaderSimple>
    );
});

const mapStateToProps = ({  }) => ({

});

HeaderSimple.propTypes = {
    className:PropTypes.string
};

export default connect(mapStateToProps)(HeaderSimple);
