import React, {Fragment, useState} from 'react';
import {ThemeProvider} from 'styled-components'
import mainTheme from './styles/themes/MainTheme'
import AppContainer from "./containers/AppContainer/AppContainer";
import { Provider } from 'react-redux';
import { store } from './helpers';
import  {Popup}  from './components/popups/Popup';
import {history} from './helpers';
import { Router } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const shouldUpdateScroll = (prevRouterProps, { location, history }) => (
    prevRouterProps && location.pathname !== prevRouterProps.location.pathname
);

function App() {
    return (
      <ThemeProvider theme={mainTheme}>
          <Provider store={store}>
              <Router history={history} >
                  <ScrollContext shouldUpdateScroll={shouldUpdateScroll}>
                      <Fragment>
                          <ToastContainer
                              hideProgressBar
                              autoClose={4000}
                              closeButton={false}
                              position="top-right"
                          />
                          <Popup />
                          <div className="App">
                             <AppContainer />
                          </div>
                      </Fragment>
                  </ScrollContext>
              </Router>
          </Provider>
      </ThemeProvider>
  );
}

export default App;
