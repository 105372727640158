import styled from "styled-components";

const StyledAvatar = styled.span`
      .user_details {
            margin-left: 16px;
            display: flex;
            &:hover, &:focus{
              text-decoration: none;
            }
            .avatar {
              width: 44px;
              height: 44px;
              border-radius: 5px;
              position: relative;
              text-align: center;
              img {
                 width: 44px;
                 height: 44px;
              }
              .notifications {
                position: absolute;
                right: -12px;
                top: -10px;
                background-color: #ffffff;
                width: 18px;
                height: 18px;
                border-radius: 4px;
                svg {
                  path {
                    fill: limegreen;
                  }
                }
                &.active {
                  svg {
                    path {
                      fill: ${({ theme }) => theme.colors.brand};
                    }
                  }
                }
              }
            }
            .name_status {
                  margin-left: 26px;
                  position: relative;
              button.logout_button{
                 margin-left: 5px; 
                 font-size: 14px;
                 color: #949494;
                 &:hover{
                  text-decoration: underline;
                 }
              }
              .name {
                color: #524965;
                font-size: 14px;
                font-weight: 500;
                max-width: 135px;
              }
              .status {
                color: ${({ theme }) => theme.colors.success};
                margin-top: 7px;
                font-size: 13px;
              }
            }
          }
`;
export default StyledAvatar;
