import { userService } from '../services';
import {GET_TRANSACTIONS_INFO} from '../config/actionTypes';
import * as toast from "../components/utility/toast";
import {modalActions} from "./modal.actions";
import {globals} from "./user_and_global_info";

export const paymentActions = {
    addDeposit,
    withdraw,
    getTransactionsHistory
};

function addDeposit(data) {
    return dispatch => {
        userService.PostInfo(`transaction/deposit`, data)
            .then(
                message => {
                    dispatch(globals.userInfo());
                    dispatch(modalActions.close_modal() )
                    toast.success({ text: message.message })
                }
            );
    };
}
function withdraw(data) {
    return dispatch => {
        userService.PostInfo(`transaction/withdraw`, data)
            .then(
                message => {
                    dispatch(globals.userInfo());
                    dispatch(modalActions.close_modal() )
                    toast.success({ text: message.message })

                }
            );
    };
}
function getTransactionsHistory(url) {
    return dispatch => {
        userService.GetInfo(`transaction`, `/${url}`)
            .then(
                transactions => {
                    dispatch(success(transactions));
                }
            );
    };
    function success(transactions) { return { type: GET_TRANSACTIONS_INFO, transactions } }
}