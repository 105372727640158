import React, { Suspense } from "react";
import Spinner from "../components/Spinner/Spinner";
import styled from "styled-components";

const StyledMain = styled.main`
  display: flex;
  min-height: 50vh;
  justify-content: center;
  align-items: center;
`;

const LoaderComponent = () => (
  <StyledMain>
    <h2 className="loading-container text-center">
      <Spinner className="spinner" color="var(--color-brand)" size={100} />
      <span className="t-hidden">loading</span>
    </h2>
  </StyledMain>
);
const LazyLoad = ({ component: Component, loader: Loader, ...rest }) => (
  <>
    <Suspense fallback={Loader ? <Loader /> : <LoaderComponent />}>
      <Component {...rest} />
    </Suspense>
  </>
);

export default LazyLoad;
