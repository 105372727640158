import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, {useState, useRef, useEffect} from "react";
import StyledHeaderMain from "./HeaderMain.styled";
import { Link } from "react-router-dom";
import SearchIcon from "../../images/icons/ic_search.svg"
import { BiCaretDown } from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import useOutsideClick from "../../helpers/useOutsideClick";
import {ReactComponent as IconCoin} from "../../images/icons/ic_coin.svg"
import {ReactComponent as IconNotification} from "../../images/icons/ic_notification.svg"
import { FiMinus, FiPlus } from "react-icons/fi"
import { CgProfile } from 'react-icons/cg';
import { useTranslation } from 'react-i18next';
import {globals} from "../../actions/user_and_global_info";
import styled from "styled-components";
import {authActions, modalActions} from "../../actions";
import Deposit from "../popups/Payment/Deposit";
import {history} from "../../helpers";
import Withdraw from "../popups/Payment/Withdraw";
import queryString from "query-string";
import UserAvatar from "../UserAvatar/userAvatar";

const HeaderMain = React.memo(props => {
    const { className, dispatch, user, backLanguages } = props;
    const [dropdownOpen, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [lang, setLang] = useState(localStorage.getItem("lang") ? localStorage.getItem("lang") : 'en');
    const { t, i18n } = useTranslation();
    const ref = useRef();

    useOutsideClick(ref, () => {
       if (dropdownOpen) setOpen(false);
    });
    const toggle = () => setOpen(!dropdownOpen);
    useEffect(()=>{
        let newUrl = queryString.parse(history.location.search)
        if(newUrl.query){
            setSearch(newUrl.query)
        }
    },[])
    useEffect(() => {
        localStorage.setItem("lang", lang)
        i18n.changeLanguage(lang);

        setOpen(false)
    }, [lang]);

    useEffect(() => {
        dispatch(globals.userInfo());
        dispatch(globals.getLanguages());
    }, []);
    const logOut = (e) => {
        e.preventDefault();
        dispatch(authActions.logOut())
    }
    const openModal = (data) =>{
        dispatch(modalActions.open_modal(data))
    }
    const searchAction = (e) =>{
        e.preventDefault()
        history.push(`/home?query=${search}`)
    }
    return   (
        <StyledHeaderMain className={className}>

            <div className="main_header_container">
                <form onSubmit={searchAction}>
                    <label className='search'>
                        <button><img src={SearchIcon} alt=""/></button>
                        <input placeholder={t("Search")} value={search} onChange={e=>setSearch(e.target.value)} type="text"/>
                    </label>
                </form>
                <div className="header_menu">
                    <nav>
                        <ul>
                            <li className="desc_menu">
                                <Link to="/faq">{t("FAQ")}</Link>
                            </li>
                            <li className="desc_menu">
                                <Link to="/about">{t("About")}</Link>
                            </li>
                            <li className="desc_menu">
                                <Link to="/support">{t("Support")}</Link>
                            </li>
                            <li className="languages" ref={ref}>
                                <span className="selected_languages" onClick={toggle}>{lang} <BiCaretDown/></span>
                                {dropdownOpen &&
                                    <div className="dropdown-lang" >
                                        {backLanguages && backLanguages.length &&
                                            backLanguages.map(item=>
                                                <div className="lan_row" onClick={()=>{setLang(item.short_name); window.location.reload();}}>
                                                    <span>{item.name}</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                }

                            </li>

                        </ul>
                    </nav>
                    <div className="user_info_header">
                        <div className="balance_box">
                            <div className="balance">
                                <div className="coins">
                                    <div className="iconCoin">
                                        <IconCoin />
                                    </div>
                                    <div className="coinCount">
                                        {user?.amount}
                                    </div>
                                </div>
                                <div className="coinsControl">
                                    <div
                                        className="plus"
                                        onClick={()=>openModal(
                                            <Deposit dispatch={dispatch} />
                                        )}
                                    >
                                        <FiPlus/>
                                    </div>
                                    <div
                                        className="minus"
                                        onClick={()=>openModal(
                                            <Withdraw dispatch={dispatch} />
                                        )}
                                    >
                                        <FiMinus/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <UserAvatar className="desc_menu" photo={user?.photo} firstName={user?.first_name} lastName={user?.last_name} dispatch={dispatch} />
                    </div>
                </div>
            </div>

        </StyledHeaderMain>
    );
});

const mapStateToProps = ({ userInfo, languagesInfo }) => ({
    user: userInfo.user,
    backLanguages: languagesInfo.languages,
});

HeaderMain.propTypes = {
    className:PropTypes.string
};

export default connect(mapStateToProps)(HeaderMain);
