import React from "react";
import { toast } from "react-toastify";
import {
    WarningToast,
    SuccessToast,
    ErrorToast
} from "../Toast/Toast";

export const success = options => {
    return toast.success(<SuccessToast {...options} />);
};

export const error = options => {
    return toast.error(<ErrorToast {...options} />);
};

export const warning = options => {
    return toast.warning(<WarningToast {...options} />);
};

export default success;
