import PropTypes from "prop-types";
import React from "react";
import StyledAvatar from "./userAvatar.styled";
import {authActions} from "../../actions";
import {CgProfile} from "react-icons/cg";
import {ReactComponent as IconNotification} from "../../images/icons/ic_notification.svg";
import {GoPrimitiveDot} from "react-icons/go";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
const StyledAvatarImage = styled.div`
    background-image: url("${props => props.backgroundAvatarImage}");
    background-position: center;
    background-size: cover;
    border-radius: 6px;
    overflow: hidden;
    width: 44px;
    height: 44px;
`;

const UserAvatar = React.memo((props) => {
  const { className, photo, firstName, lastName, dispatch, color } = props;
    const logOut = (e) => {
        e.preventDefault();
        dispatch(authActions.logOut())
    }
    const { t } = useTranslation();
  return (
    <StyledAvatar className={className} color={color}>
        <Link to="/account" className="user_details">
            <div className="avatar">
                {photo ?
                    <StyledAvatarImage className="profile_image" backgroundAvatarImage={photo} />
                    :<CgProfile size="50px" color="#9c9c9c"/>}

                <div className="notifications">
                    <IconNotification />
                </div>
            </div>
            <div className="name_status">
                <div className="name">
                    {firstName} {lastName}
                </div>
                <div className="status">
                    <GoPrimitiveDot />{t('online')}
                    <button className="logout_button" onClick={logOut}>{t("Log out")}</button>
                </div>

            </div>
        </Link>
    </StyledAvatar>
  );
});

UserAvatar.propTypes = {
  photo: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  className: PropTypes.string,
};


export default UserAvatar;
