import io  from 'socket.io-client';
import {socketUrl} from '../config/constants'
// import {io} from "socket.io-client";
// eslint-disable-next-line no-undef
export const socket = (token) => io(socketUrl, {
  // transports: ["polling"],
  query: {
    token: token
  }
})
