import { userService } from '../services';
import React from 'react';
import {alertActions, modalActions} from './';
import { history } from '../helpers';
import * as toast from "../components/utility/toast";
import {globals} from "./user_and_global_info";
import Authentication from "../components/popups/Authentication/Authentication";

export const authActions = {
    register,
    login,
    forgotPassword,
    resetPassword,
    logOut,
    verifyEmail,
    socialLogin
};

function logOut(path = "/") {
    return () => {
        localStorage.removeItem('token');
        localStorage.removeItem('LoginStatus');
        history.push(path)
        window.location.reload();
    }
}

function register(user) {
    return dispatch => {
        userService.register('register',user)
            .then(
                data => {
                    dispatch(modalActions.close_modal())
                    toast.success({ text: data.message })
                },
                error => {
                    if(error.errorCode !== 403){
                        dispatch(alertActions.verify_error(error.errors));
                    }
                }
            );
    };
}

function login(user) {
    return dispatch => {
        userService.login('login', user)
            .then(
                () => {
                    dispatch(modalActions.close_modal())
                    dispatch(globals.userInfo());
                    history.push('/home');
                },
                error => {
                    if(error.errorCode !== 403){
                        dispatch(alertActions.verify_error(error.errors));
                    }
                }
            )
    };

}

function verifyEmail(data) {
    return dispatch => {
        userService.GetInfo('email-activate/', data)
            .then(
                user => {
                    if (user.token ) {
                        localStorage.setItem('LoginStatus', true);
                        localStorage.setItem('token', JSON.stringify(user.token));
                        history.push('/account')
                        toast.success({ text: "Your account is confirmed, please fill your info" })
                    }
                },
                error => {
                    history.push('/')
                }
            );
    };

}

function forgotPassword(user) {
    return dispatch => {
        userService.PostInfo('forgot/password-email',user)
            .then(
                data => {
                    dispatch(modalActions.close_modal())
                    toast.success({ text: data.message })
                },
                error => {
                    if(error.errorCode !== 403){
                        dispatch(alertActions.verify_error(error.errors));
                    }
                }
            )
    };
}

function resetPassword(data) {
    return dispatch => {
        userService.PostInfo(`forgot/password-reset`,data)
            .then(
                result => {
                    dispatch(modalActions.open_modal(<Authentication dispatch={dispatch} type="login" />))
                    dispatch(alertActions.clear());
                    toast.success({ text: result.message })
                },
                error => {
                    if(error.errorCode !== 403){
                        dispatch(alertActions.verify_error(error.errors));
                    }
                }
            );
    };
}

function socialLogin(provider, token, tokenSecret) {
  return dispatch => {
    let url = `oauth/${provider}/callback?token=${token}`
    if(tokenSecret) {
      url = url+`&token_secret=${tokenSecret}`
    }
    userService.loginSocial(url)
      .then(
        () => {
          dispatch(modalActions.close_modal())
          dispatch(globals.userInfo());
          history.push('/home');
        },
        error => {
          if(error.errorCode !== 403){
            dispatch(alertActions.verify_error(error.errors));
          }
        }
      )
  };

}
