import styled from "styled-components";

const StyledAuthor = styled.main`
    width: 100%;
    .author_section{
    display: flex;
    
    .author_information{
      margin-left: 25px;
        h1{
          font-size: 28px;
          font-weight: 500;
          margin-bottom: 15px;
        }
        .quizzes_info{
          font-size: 14px;
          margin-bottom: 15px;
        }
        .author_desc{
          font-size: 14px;
          opacity: 0.5;
          margin-bottom: 24px;
          line-height: 1.286;
          max-width: 700px;
        }
        .d-flex{
          b{
            font-weight: 700;
          }
          button{
            width: 100px;
            margin-left: 24px;
          }
        }
      }
    }
    .home_games-slider{
      width: 100%;
      padding: 10px 0;
      .main_container{
        padding: 0;
        width: 100%;
      }
    }
    
`;

export default StyledAuthor;
