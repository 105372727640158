import styled from "styled-components";

const StyledHeaderSimple = styled.header`
    background-color: #dfdfe4;
    padding: 17px 15px 17px;
    .main_container{
      display: flex;
      justify-content: space-between;
      nav ul{
        display: flex;
        align-items: center;
        li{
        margin-left: 46px;
          a{
            color: #524965;
            font-size: 14px;
            font-family: var(--font-roboto);
            font-weight: 500;
          }
          &.modal_list{
            margin-left: 100px;
            @media(max-width: 991px){
              margin-left: 0;
            }
            button{
              font-size: 14px;
              font-family: var(--font-roboto);
              font-weight: 700;
            }
          }
          .registration_button{
            display: flex;
            align-items: center;
            img{
              margin-left: 30px;
            }
          }
        }
      }
    }
    @media(max-width: 991px){
      .mobile_menu-item{
        display: none;
      }
    }
`;
export default StyledHeaderSimple;
