import {GET_ANSWER_INFO} from '../config/actionTypes';

export function answerInfo(state = {}, action) {
    switch (action.type) {
        case GET_ANSWER_INFO:
            return {
                answerInfo: action.answerInfo,
            };
        default:
            return state
    }
}
