/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import getRoutes from "../../config/routes";
import { connect } from "react-redux";
import {socket} from "../../helpers/socketClient"

export const SocketContext = React.createContext('');
//
const AppContainer = (props) => {
  const { dispatch,   user } = props;
  const [socketState, setSocketState] = useState();

//socket connection initialization

  useEffect(() => {
    let connectSocket;
    if(user && !connectSocket && !socketState){
      const token = JSON.parse(localStorage.getItem('token'));
      connectSocket = socket(token)
        console.log('connectSocket', connectSocket)
      setSocketState(connectSocket)
        connectSocket.on('connect', function () {
            console.log("Socket.io connected...")
        })
      //   connectSocket.on(`channel_name`, (data) => {
      //   console.log(data)
      // });
      //   connectSocket.emit('test', { foo: 'bar' }, function (data) {
      //       console.log(data)
      //   })
    }
  }, [user]);


  return (
    <>
      <SocketContext.Provider value={socketState}>
        {getRoutes()}
      </SocketContext.Provider>
    </>
  );
};

const mapStateToProps = ({userInfo}) => ({
    user: userInfo.user,
});

AppContainer.propTypes = {
    userInfo: PropTypes.object
};

export default connect(mapStateToProps)(AppContainer);
