import { combineReducers } from 'redux';
import { loading } from './request.reducer';
import { modals } from './modal.reducer';
import { alert } from './alert.reducer';
import * as info from './getInfo.reducer';
import * as games from './games.reducer';
import * as transactions from './transactions.reducer';
import * as loader from './loader.reducer';
import * as loaderPopular from './loaderPopular.reducer';
import * as answerInfo from './getAnswer.reducer';
import * as cropper from './cropper.reducer';
import * as getStaticPages from './getStaticPages.reducer';

const rootReducer = combineReducers({
      loading,
      modals,
      alert,
      ...info,
      ...games,
      ...transactions,
      ...loader,
      ...loaderPopular,
      ...answerInfo,
      ...cropper,
      ...getStaticPages
});

export default rootReducer;