import { ADD_CROP_IMAGE, CLEAR_CROP_IMAGE, SAVE_CROP_IMAGE } from '../config/actionTypes'

export function crop(state = {}, action) {
  switch (action.type) {
    case ADD_CROP_IMAGE:
      return {
        cropImage: action.image,
        croppedImage:''
      };
    case  SAVE_CROP_IMAGE:
      return {
        cropImage: '',
        croppedImage: action.image
      };
     case CLEAR_CROP_IMAGE:
      return {

      };
    default:
      return state
  }
}