import React from "react";
import StyledMainPage from "./MainPage.styled";
import {connect} from "react-redux";
import SideMenu from "../../components/SideMenu/SideMenu";
import HeaderMain from "../../components/HeaderMain/HeaderMain";
import HeaderSimple from "../../components/HeaderSimple/HeaderSimple";
import {useMediaQuery} from "react-responsive";

const MainPage = (props) => {
  const {children, isHome, noLogin} = props;
  const mediaMenu = useMediaQuery({
        query: '(max-width: 991px)'
  })
  return (
    isHome ?
      <StyledMainPage>
        <SideMenu/>
        <div className='main_content'>
          <HeaderMain/>
          {children}
        </div>
      </StyledMainPage>
      :
        noLogin &&  !mediaMenu?
            children
            :
            <StyledMainPage>
                <SideMenu/>
                <div className='main_content'>
                    <HeaderSimple />
                    {children}
                </div>
            </StyledMainPage>
  )
};

function mapStateToProps() {
  return {

  };
}

export default connect(mapStateToProps)(MainPage);