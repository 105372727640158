import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, {useState, useEffect} from "react";
import { FaGem, FaHeart } from "react-icons/fa"
import StyledSideMenu from "./SideMenu.styled";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../images/logo.svg";
import MenuCollapse from "./MenuCollapse";
import {ReactComponent as IcMenu} from "../../images/icons/ic_menu.svg"
import {globals} from "../../actions/user_and_global_info";
import { FcMenu } from "react-icons/fc";
import {history} from "../../helpers";
import queryString from "query-string";
import {useMediaQuery} from "react-responsive";

const menuItemsStatic = [

    {
        name: "Games",
        first: true,
        level: 1,
        path: "/home",
    },
    {
        name: "All Games",
        level: 2,
        path: "/home"
    },
    {
        name: "Categories",
        level: 2,
        children: [

        ]
    },
    {
        name: "Recommended",
        level: 2,
        path: "/home?category=1&category_name=Recommended"
    },
    {
        name: "New!",
        level: 2,
        path: "/home?is_new=true"
    },

]

const SideMenu = React.memo(props => {
    const {className, categories, dispatch} = props;
    const [menuItems, setMenuItems] = useState(menuItemsStatic)
    const [openMenu, setOpenMenu] = useState(false)
    useEffect(()=>{
        dispatch(globals.getCategories());
    },[])
    useEffect(()=>{
        if(categories){
            const newMenu =menuItems.map(item=>{
                if(item.name === "Categories"){
                    item.children = categories.map(item1 => {return {name: item1.name, path: `/home?category=${item1.id}&category_name=${item1.name}`,  level: 3, IconPath:  IcMenu, img: item1.photo  }})
                    return {...item, children: item.children}
                }
                else return item;
            })
            setMenuItems(newMenu)
        }




    },[categories])
    const openMenuFunc = () =>{
        setOpenMenu(!openMenu)
        document.querySelector('.main_content').classList.toggle('fixed')
    }
    const device = useMediaQuery({
        query: '(max-width: 1150px)'
    })
    useEffect(()=>{

        const listen = history.listen(() => {
            if(device){
                setOpenMenu(false)
                document.querySelector('.main_content').classList.remove('fixed')
            }
        });
        // Remove event listener on cleanup
        return () => {
            listen()
        };
    })
    return (
        <StyledSideMenu className={openMenu ? 'active' : '' }>
            <button className="open_menu" onClick={openMenuFunc}><FcMenu size={40} /></button>
            <Link to="/" className="logo"><img src={Logo} alt="logo"/></Link>
            <MenuCollapse menuItems={menuItems} />
        </StyledSideMenu>
    );
});

const mapStateToProps = ({ categoriesInfo }) => ({
    categories: categoriesInfo.categories
});

SideMenu.propTypes = {
    className:PropTypes.string
};

export default connect(mapStateToProps)(SideMenu);
