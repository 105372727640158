import {
    GET_TRANSACTIONS_INFO
} from '../config/actionTypes';

export function getTransactionHistory(state = {}, action) {
    switch (action.type) {
        case GET_TRANSACTIONS_INFO:
            return {
                transactions: action.transactions,
            };
        default:
            return state
    }
}