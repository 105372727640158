import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import InputText from "../../ui/Inputs/InputText";
import Button from "../../ui/Button";
import EmptyButton from "../../ui/EmptyButton";
import Authentication from "./Authentication";
import {authActions, modalActions} from "../../../actions";
import ForgotPassword from "./ForgotPassword";
import {connect} from "react-redux";

const StyledLogin= styled.form`
  .auth_head {
    color: #524965;
    margin: 20px 0;
    h1 {
      font-size: 18px;
      padding: 10px 0;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      opacity: 0.5;
    }
  }
  .input_area {
    padding: 20px 0;
    input {
      font-size: 14px;
    }
  }
  .button_area {
    .loginSubmit {
      text-transform: uppercase;
      margin: 5px 0;
    }
    .forgotPassword {
      margin: 5px 0;
      opacity: 0.5;
    }
  }
`;

const Login = (props) => {
  const {
      errors,
      dispatch
  } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const openModal = (e,data) =>{
    e.preventDefault()
    dispatch(modalActions.open_modal(data))
  }
    const handleSignIn = (e) =>{
        e.preventDefault();
        dispatch(authActions.login({
            email, password
        }));
    }
  return (
    <StyledLogin onSubmit={handleSignIn}>
      <div className="auth_head">
        <h1>Welcome Back</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod </p>
      </div>
      <div className="input_area">
        <InputText type="email"
                   value={email}
                   onChange={(e)=>setEmail(e.target.value)}
                   label="Email Address"
                   errorMessage={errors?.email?.[0]}
        />
        <InputText
            type="password"
            value={password}
            onChange={(e)=>setPassword(e.target.value)}
            label="Password"
            errorMessage={errors?.password?.[0]}
        />
      </div>
      <div className="button_area">
        <Button type="submit" className="loginSubmit" width='100%'>Login</Button>
        <EmptyButton className="forgotPassword" width='100%' onClick={(e) => openModal(
          e,
          <ForgotPassword/>
        )}>Forgot Password?</EmptyButton>
      </div>
    </StyledLogin>
  )
};
Login.propTypes = {
  // type: PropTypes.string
};

function mapStateToProps({ alert }) {
    return {
        errors: alert.message
    };
}
export default connect(mapStateToProps)(Login);
