
export function authHeader( type ) {
    const token = JSON.parse(localStorage.getItem('token'));
    let lang = localStorage.getItem("lang");
    lang = lang ? lang : 'en'
    if(type==='file'){
        return {
            'Access-Control-Allow-Origin': '*',
            'accept':'application/json',
            'Authorization': 'Bearer ' + token,
            'Accept-Language': lang,
            // ...header
        };
    }
    if (token ) {
        return {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token,
            'Accept-Language': lang,
            // ...header
        };
    }
    else {
        return {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Accept-Language': lang,
            // ...header
        };
    }
}