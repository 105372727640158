import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import InputText from "../../ui/Inputs/InputText";
import Button from "../../ui/Button";
import FacebookSvg from '../../../images/icons/ic_fb.svg'
import GoogleSvg from '../../../images/icons/ic_google.svg'
import TwitterSvg from '../../../images/icons/ic_twitter.svg'
import InputCheckbox from "../../ui/Inputs/InputCheckbox";
import {connect} from "react-redux";
import {authActions} from "../../../actions";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login';
import TwitterLogin from "react-twitter-login";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


const StyledRegistration= styled.form`
  .auth_head {
    color: #524965;
    margin: 20px 0;
    h1 {
      font-size: 18px;
      padding: 10px 0;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      opacity: 0.5;
    }
  }
  .input_area {
    padding: 20px 0 0 0;
    input {
      font-size: 14px;
    }
  }
  .button_area {
    margin-bottom: 20px;
    .loginSubmit {
      text-transform: uppercase;
      margin: 5px 0;
    }
  }
  .agree_terms_box {
    margin: 5px 0;
    display: flex;
    a {
      color: ${({ theme }) => theme.colors.brand};
      margin-left: 5px;
      font-size: 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
  .divider_line {
    border-bottom: 2px solid #524965;
    opacity: 0.1;
  }
  .social_media_box {
    .social_media_box__head {
      opacity: 0.5;
      color: #524965;
      font-size: 14px;
      padding: 20px 0;
    }
    .social_media_box__buttons {
      display: flex;
      justify-content: space-between;
      position: relative;
       &.disabled_social:after{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: rgba(243, 243, 246, 0.7 );
          cursor: not-allowed;
        }
      .facebook_sign_up,
       .google_sign_up,
       .twitter_sign_up {
        padding: 11px 43px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
      }
      .facebook_sign_up {
        background-color: #3B5998;
      }
      .google_sign_up {
        background-color: #ffffff;
      }
      .twitter_sign_up {
        background-color: #00ACED;
      }
    }
  }
`;

const Registration = (props) => {
  const {
      errors,
      dispatch
  } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agree, setAgree] = useState(false);
  const responseFacebook = (response) => {
    dispatch(authActions.socialLogin("facebook", response.accessToken ))
  }
  const responseGoogle = (response) => {
    if(response && response.accessToken) {
      dispatch(authActions.socialLogin("google", response.accessToken))
    }
  }
  const authHandler = (err, data) => {
    if(data && data.oauth_token) {
      dispatch(authActions.socialLogin("twitter", data.oauth_token, data.oauth_token_secret))
    }
  };
  const handleSignUp = (e) =>{
        e.preventDefault();
        dispatch(authActions.register({
            email, password
        }));
  }
  const { t } = useTranslation();
  return (
    <StyledRegistration onSubmit={handleSignUp}>
      <div className="auth_head">
        <h1>{t("Sign up Now it’s Easy!")}</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod </p>
      </div>
      <div className="input_area">
        <InputText type="email"
                   value={email}
                   onChange={(e)=>setEmail(e.target.value)}
                   label={t("Email Address")}
                   errorMessage={errors?.email?.[0]}
        />
        <InputText type="password"
                   value={password}
                   onChange={(e)=>setPassword(e.target.value)} l
                   label={t("Password")}
                   errorMessage={errors?.password?.[0]}
        />
      </div>
      <div className="agree_terms_box">
        <InputCheckbox type="checkbox" onChange={(e)=>setAgree(!agree)} defaultValue={agree} label="I agree with" />
        <Link to="/terms-conditions" target="_blank" rel="noopener noreferrer" >The Terms and Conditions</Link>
      </div>
      <div className="button_area">
        <Button type="submit" className="loginSubmit" width='100%' disabled={!agree}>Register</Button>
      </div>
      <div className="divider_line"/>
      <div className="social_media_box">
        <div className="social_media_box__head">
          Or Sign up using social networks
        </div>
        <div className={`social_media_box__buttons ${!agree ? 'disabled_social' :''}`}>
          <FacebookLogin
            appId="229437282205686"
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            render={renderProps => (
              <div onClick={renderProps.onClick} className="facebook_sign_up">
                <img src={FacebookSvg} alt=""/>
              </div>
            )}
          />
          <GoogleLogin
            clientId="110515838395-hvqtdr996vkjo21ksbgu2b2g92v33549.apps.googleusercontent.com"
            buttonText="Login"
            render={renderProps => (
              <div onClick={renderProps.onClick}  className="google_sign_up">
                <img src={GoogleSvg} alt=""/>
              </div>
            )}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
          />
          <TwitterLogin
            authCallback={authHandler}
            consumerKey="HckoXUwJgPO2XXbcApo7lJqtl"
            consumerSecret="Js0JBTdNU1JI5DMqQegulKRpIPKvcqc3z8sih1HBo2TRYK4ydl"
          >
            <div className="twitter_sign_up">
              <img src={TwitterSvg} alt=""/>
            </div>
          </TwitterLogin>

        </div>
      </div>
    </StyledRegistration>
  )
};
Registration.propTypes = {
  // type: PropTypes.string
};
function mapStateToProps({ alert }) {
    return {
        errors: alert.message
    };
}
export default connect(mapStateToProps)(Registration);
