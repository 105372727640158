import {
    GET_POPULAR_GAMES,
    GET_GAMES, GET_ONE_GAME, GET_END_GAME, GET_ANSWERS_END_GAME, REQUEST_POPULAR_GAMES, ERROR_POPULAR_LOADER,  REQUEST_STANDARD_GAMES, ERROR_STANDARD_GAMES, CLEAR_STANDARD_GAMES
} from '../config/actionTypes';


export function popularGames(state = {popularGames:{data:[]}, loader:false}, action) {
    switch (action.type) {
        case REQUEST_POPULAR_GAMES:
            return {
               ...state,
                loader: true
            };
        case ERROR_POPULAR_LOADER:
            return {
               ...state,
                loader: false
        };
        case GET_POPULAR_GAMES:
            let obj;
            if(state.popularGames && action.popularGames && action.popularGames.current_page !== 1) {
                obj = [...state.popularGames.data, ...action.popularGames.data]
            } else if(state.popularGames && action.popularGames && action.popularGames.current_page === 1) {
                obj = action.popularGames.data
            }

            return {
                popularGames:{...action.popularGames, data: obj},
                loader: false
            };
        default:
            return state
    }
}
export function getGames(state = {games:{data:[]}, loader:false}, action) {
    switch (action.type) {
        case REQUEST_STANDARD_GAMES:
            return {
                ...state,
                loader: true
            };
        case CLEAR_STANDARD_GAMES:
            return {
                games:{data:[]},
                loader: false
            };
        case ERROR_STANDARD_GAMES:
            return {
                ...state,
                loader: false
            };
        case GET_GAMES:
            let obj;
            if(state.games && action.games && action.games.current_page !== 1) {
                obj = [...state.games.data, ...action.games.data]
            }
            else if(state.games && action.games && action.games.current_page === 1) {
                obj = action.games.data
            }
            return {
                games: {...action.games, data: obj},
                loader: false
            };
        default:
            return state
    }
}
export function getOneGame(state = {}, action) {
    switch (action.type) {
        case GET_ONE_GAME:
            return {
                game: action.game,
            };
        default:
            return state
    }
}
export function getEndGame(state = {}, action) {
    switch (action.type) {
        case GET_END_GAME:
            return {
                gameInfo: action.gameInfo,
            };
        default:
            return state
    }
}
export function getAnswersEndGame(state = {}, action) {
    switch (action.type) {
        case GET_ANSWERS_END_GAME:
            return {
                gameAnswersInfo: action.gameAnswersInfo,
            };
        default:
            return state
    }
}