export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const SUCCESS_ALERT = 'SUCCESS_ALERT';
export const ERROR_ALERT = 'ERROR_ALERT';
export const ERROR_ALERT_VERIFY = 'ERROR_ALERT_VERIFY';
export const CLEAR_ALERT = 'CLEAR_ALERT';


export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_LANGUAGES_INFO = 'GET_LANGUAGES_INFO';
export const GET_CATEGORIES_INFO = 'GET_CATEGORIES_INFO';


export const GET_POPULAR_GAMES = 'GET_POPULAR_GAMES';
export const REQUEST_POPULAR_GAMES = 'REQUEST_POPULAR_GAMES';
export const ERROR_POPULAR_LOADER = 'ERROR_POPULAR_LOADER';
export const  REQUEST_STANDARD_GAMES = 'REQUEST_STANDARD_GAMES';
export const  ERROR_STANDARD_GAMES = 'ERROR_STANDARD_GAMES';
export const  CLEAR_STANDARD_GAMES = 'CLEAR_STANDARD_GAMES';
export const GET_GAMES = 'GET_GAMES';
export const GET_END_GAME = 'GET_END_GAME';
export const GET_ANSWERS_END_GAME = 'GET_ANSWERS_END_GAME';
export const GET_ONE_GAME = 'GET_ONE_GAME';
export const REQUEST_LOADER = 'REQUEST_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const REQUEST_POPULAR_LOADER = 'REQUEST_POPULAR_LOADER';
export const STOP_POPULAR_LOADER = 'STOP_POPULAR_LOADER';


export const GET_TRANSACTIONS_INFO = 'GET_TRANSACTIONS_INFO';

export const GET_ANSWER_INFO = 'GET_ANSWER_INFO';


export const ADD_CROP_IMAGE = 'ADD_CROP_IMAGE';
export const CLEAR_CROP_IMAGE = 'CLEAR_CROP_IMAGE';
export const SAVE_CROP_IMAGE = 'SAVE_CROP_IMAGE';

export const GET_STATIC_PAGE = 'GET_STATIC_PAGE';




