import PropTypes from "prop-types";
import React from "react";
import StyledSpinner from "./Spinner.styled";

const Spinner = React.memo((props) => {
  const { className, size, color } = props;

  return (
    <StyledSpinner className={className} color={color}>
      <svg
        className="loading-spinner"
        fill="#000"
        height={`${size}px`}
        viewBox="0 0 66 66"
        width={`${size}px`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="path"
          cx="33"
          cy="33"
          fill="none"
          r="30"
          // stroke={color || "#000"}
          strokeLinecap="round"
          strokeWidth="6"
        />
      </svg>
    </StyledSpinner>
  );
});

Spinner.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number,
  className: PropTypes.string.isRequired,
};

Spinner.defaultProps = {
  size: 50,
};

export default Spinner;
