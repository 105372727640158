import React, { lazy } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import LazyLoad from "./LazyLoad";
import SideMenu from "../components/SideMenu/SideMenu";
import MainPage from "../containers/MainContainer/MainPage";
import HeaderMain from "../components/HeaderMain/HeaderMain";
import {history} from "../helpers";
import StyledAuthor from "../containers/Author/Author.styled";
/**
|--------------------------------------------------
| Route Config
|--------------------------------------------------
*/

const Home = lazy(() => import("../containers/Home/HomeContainer"));
const LazyHome = (props) => <LazyLoad component={Home} {...props} />;

const HomeQuiz = lazy(() => import("../containers/HomeQuiz/HomeQuizContainer"));
const LazyHomeQuiz = (props) => <LazyLoad component={HomeQuiz} {...props} />;

const Quiz = lazy(() => import("../containers/Quiz/Quiz"));
const LazyQuiz = (props) => <LazyLoad component={Quiz} {...props} />;

const GamePage = lazy(() => import("../containers/GamePage/GamePageContainer"));
const LazyGamePage = (props) => <LazyLoad component={GamePage} {...props} />;

const Account = lazy(() => import("../containers/Account/AccountContainer"));
const LazyAccount = (props) => <LazyLoad component={Account} {...props} />;

const GameEndPage = lazy(() => import("../containers/GameEndPage/GameEndPageContainer"));
const LazyGameEndPage = (props) => <LazyLoad component={GameEndPage} {...props} />;

const About = lazy(() => import("../containers/About/AboutContainer"));
const LazyAbout = (props) => <LazyLoad component={About} {...props} />;

const Support = lazy(() => import ("../containers/Support/SupportContainer"));
const LazySupport = (props) => <LazyLoad component={Support} {...props} />;

const Faq = lazy(() => import("../containers/Faq/FaqContainer"));
const LazyFaq = (props) => <LazyLoad component={Faq} {...props} />;

const Author= lazy(() => import("../containers/Author/AuthorContainer"));
const LazyAuthor = (props) => <LazyLoad component={Author} {...props} />;

const EmailVerification= lazy(() => import("../containers/EmailVerification/EmailVerificationContainer"));
const LazyEmailVerification = (props) => <LazyLoad component={EmailVerification} {...props} />;

const Deposit= lazy(() => import("../containers/Deposit/DepositContainer"));
const LazyDeposit = (props) => <LazyLoad component={Deposit} {...props} />;

const QuizAnswers= lazy(() => import("../containers/QuizAnswers/QuizAnswers"));
const LazyQuizAnswers = (props) => <LazyLoad component={QuizAnswers} {...props} />;

const Privacy= lazy(() => import("../containers/Privacy/PrivacyContainer"));
const LazyPrivacy = (props) => <LazyLoad component={Privacy} {...props} />;

const Transactions= lazy(() => import("../containers/TransactionHistory/TransactionHistoryContainer"));
const LazyTransactions = (props) => <LazyLoad component={Transactions} {...props} />;

const TermsConditions = lazy(() => import("../containers/TermsConditions/TermsConditionsContainer"));
const LazyTermsConditions = (props) => <LazyLoad component={TermsConditions} {...props} />;


export default function getRoutes() {
  const isHome =  localStorage.getItem("token")
  const noLogin =   history.location.pathname === "/" || history.location.pathname === "/about" ||  history.location.pathname === "/privacy-policy" ||  history.location.pathname === "/terms-conditions";
  return (
    <MainPage isHome={isHome} noLogin={noLogin}>
      <Switch>
        <HomeRoute path="/" exact={true} component={ LazyHome }/>
        <Route path="/home" exact={true} component={LazyHomeQuiz} />
        <PrivateRoute path="/quiz/:id" exact={true} component={LazyQuiz} />
        <Route path="/game/:id" exact={true} component={LazyGamePage} />
        <PrivateRoute path="/account" exact={true} component={LazyAccount} />
        <PrivateRoute path="/end-game/:id" exact={true} component={LazyGameEndPage} />
        <PrivateRoute path="/author/:id" exact={true} component={LazyAuthor} />
        <PrivateRoute path="/quiz-answers/:id" exact={true} component={LazyQuizAnswers} />
        {/*<PrivateRoute path="/author/:id" exact={true} component={LazyAuthor} />*/}
        <Route path="/about" exact={true} component={LazyAbout} />
        <Route path="/support" exact={true} component={LazySupport} />
        <Route path="/privacy-policy" exact={true} component={LazyPrivacy} />
        <Route path="/faq" exact={true} component={LazyFaq} />
        <Route path="/deposit" exact={true} component={LazyDeposit} />
        <Route path="/terms-conditions" exact={true} component={LazyTermsConditions} />
        <PrivateRoute path="/transactions" exact={true} component={LazyTransactions} />
        {/*<RedirectRoute path="/login" exact={true} component={LazyLogin} />*/}
        <Route path="/email-activate/:token" exact={true} component={LazyEmailVerification}/>
      </Switch>
    </MainPage>
  );
}

/**
|--------------------------------------------------
| Private Route Function
|--------------------------------------------------
*/
export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
        localStorage.getItem("token") ? (
            <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);
export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !localStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/home", state: { from: props.location } }}
        />
      )
    }
  />
);
export const HomeRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("token") ? (
        <Redirect
          to={{ pathname: "/home", state: { from: props.location } }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);
