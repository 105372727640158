import {OPEN_MODAL, CLOSE_MODAL} from '../config/actionTypes'

const initialState = {
    open_modal: false,
    children: null,
}

export function modals(state = initialState, action) {
    switch (action.type){
        case OPEN_MODAL:
            return{ open_modal: true, children: action.children}
        case CLOSE_MODAL:
            return{ open_modal: false, children: null }
        default:
            return state
    }
}
