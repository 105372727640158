import {
    GET_STATIC_PAGE
} from '../config/actionTypes';

export function getStaticPages(state = {}, action) {
    switch (action.type) {
        case GET_STATIC_PAGE:
            return {
                page: action.page,
            };
        default:
            return state
    }
}

