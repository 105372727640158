import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import Eye from "../../../images/icons/ic_eye.svg"
import EyeOff from "../../../images/icons/ic_eye_passiv.svg"

const StyledInputText = styled.label`
  position: relative;
  width: 100%;
  &.errorLabel{
   input {
    border: 1px solid #ff0000;
    color: #ff0000;
   }
  }
  input {
    width: 100%;
    padding: 23px 15px 10px 15px;
    box-sizing: border-box;
    margin: 5px 0;
    border-radius: 4px;
    border-color: transparent;
    background-color: #ffffff;
    &:focus {
      outline: none;
    }
  }
  span.lab_txt {
   position: absolute;
   top: 25px;
   left: 15px;
   transform: translateY(0);
   transition: var(--button-transition);
   opacity: 0.5;
  }
  span.errorMessage{
    color: #ff0000;
    font-size: 12px;
    margin-left: 15px;
  }
  input:focus + span.lab_txt,
  input.notEmpty + span.lab_txt
  {
    transform: translateY(-10px);
    font-size: 10px;
  }
  .eye_control{
    position: absolute;
    right: 15px;
    top: 25px;
    display:none;
  }
  input.notEmpty + span.lab_txt +.eye_control{
    display: block;
  }
`;

const InputText = (props) => {
  const { type, value, onChange, label, passwordIcon, min, max, inputName, errorMessage } = props;
  const [passwordShow, setPasswordShow] = useState(true);
  return (
    <StyledInputText className={errorMessage && "errorLabel"}>
      <input className={value || type==="date"?"notEmpty":""} name={inputName} type={passwordShow ? type :'text'} value={value} onChange={onChange} min={min} max={max}/>
      <span className="lab_txt">{label}</span>
      {passwordIcon && <div className="eye_control" onClick={()=>setPasswordShow(!passwordShow)}>{passwordShow? <img src={Eye} alt=""/>: <img src={EyeOff} alt=""/>}</div>}
      {errorMessage && <span className="errorMessage">{errorMessage}</span>}
    </StyledInputText>
  )
};
InputText.propTypes = {
  // type: PropTypes.string
};

export default React.memo(props => <InputText {...props} />);
