import { SUCCESS_ALERT, ERROR_ALERT, ERROR_ALERT_VERIFY, CLEAR_ALERT } from '../config/actionTypes'

export const alertActions = {
    success,
    error,
    verify_error,
    clear
};

function success(message) {
    return { type: SUCCESS_ALERT, message };
}
function error(message) {
    return { type: ERROR_ALERT, message };
}
function verify_error(message) {
    return { type: ERROR_ALERT_VERIFY, message };
}
function clear() {
    return { type: CLEAR_ALERT };
}