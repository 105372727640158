import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import Login from "./Login";
import Registration from "./Registration";
import InputText from "../../ui/Inputs/InputText";
import Button from "../../ui/Button";
import EmptyButton from "../../ui/EmptyButton";
import {authActions} from "../../../actions";
import {connect} from "react-redux";

const StyledForgotPassword= styled.form`
  background-color: hsl(240deg 68% 16% / 5%);
  max-width: 400px;
  padding: 0 20px 20px;
  .forgot-password__header {
    border-bottom: 2px solid #5249651a;
    padding: 15px 0;
    color: #524965;
    opacity: 0.8;
    font-size: 18px;
    font-weight: 500;
  }
  .forgot-password__text {
    padding: 28px 0;
    color: #524965;
    opacity: 0.5;
    font-size: 14px;
  }
  .input_area {
    margin-bottom: 33px;
    input {
      font-size: 14px;
    }
  }
  .button_area {
    .resetSubmit {
      text-transform: uppercase;
      margin: 5px 0;
    }
  }
`;

const ForgotPassword = (props) => {
  const { dispatch, errors } = props;
  const [email, setEmail] = useState('');
    const handleSubmit = (e) =>{
        e.preventDefault();
        dispatch(authActions.forgotPassword({
            email
        }));
    }
  return (
    <StyledForgotPassword onSubmit={handleSubmit}>
      <div className="forgot-password__header">
        Forgot password?
      </div>
      <div className="forgot-password__text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      </div>
      <div className="input_area">
        <InputText type="email"
                   value={email}
                   onChange={(e)=>setEmail(e.target.value)}
                   label="Email Address"
                   errorMessage={errors?.email?.[0]}
        />
      </div>
      <div className="button_area">
        <Button type="submit" className="resetSubmit" width='100%'>reset my password</Button>
      </div>
    </StyledForgotPassword>
  )
};
function mapStateToProps({ alert }) {
    return {
        errors: alert.message
    };
}
export default connect(mapStateToProps)(ForgotPassword);
